body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #051c33;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-nav li {
  width: 100%;
  margin: 10px;
  margin-bottom: 1em;
}
.main-nav ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding-left: 0;
}

.main-nav a {
  display: block;
  background: #051c33;
  border-radius: 3px;
  padding: 5px;
  color: #fff;
}

.main-nav .active,
.main-nav a:hover {
  background-color: #051c33;
}

.main-body {

  background: #051c33;
  color: #fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
  object-fit: none;
  border-radius: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite .1s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50%{
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
